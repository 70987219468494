import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import AOS from "aos"
import "aos/dist/aos.css"
import ReactPlayer from "react-player"
import Cookies from "universal-cookie"

// import Layout from "./layout"
// import SEO from "./seo"

import gummiPurpleXl from "../images/gummies/gummi-purple-xl.png"
import map from "../images/map.png"
import pointerGreen from "../images/pointers/pointer-green.png"
import pointerBlue from "../images/pointers/pointer-blue.png"
import pointerDarkGreen from "../images/pointers/pointer-dark-green.png"
import pointerShadow from "../images/pointers/pointer-shadow.png"
import logoSkittlesGummies from "../images/logo-skittles-gummies.png"
import originalGummies from "../images/original-gummies.png"
import gummiOrange from "../images/gummies/gummi-orange.png"
import gummiGreen from "../images/gummies/gummi-green.png"
import gummiGreenLg from "../images/gummies/gummi-green-lg.png"
import gummiYellow from "../images/gummies/gummi-yellow.png"
import gummiPurple from "../images/gummies/gummi-purple.png"
import gummiRed from "../images/gummies/gummi-red.png"
import sktGummiesOriginals from "../images/skt-gummies-originals.png"
import sktGummiesWildBerry from "../images/skt-gummies-wild-berry.png"
import gummiPurpleSm from "../images/gummies/gummi-purple-sm.png"
import gummiOrangeSm from "../images/gummies/gummi-orange-sm.png"
import gummiYellowSm from "../images/gummies/gummi-yellow-sm.png"
import gummiRedSm from "../images/gummies/gummi-red-sm.png"
import gummiGreenSm from "../images/gummies/gummi-green-sm.png"
import gummiGreenXl from "../images/gummies/gummi-green-xl.png"
import gummiOrangeRightCut from "../images/gummies/gummi-orange-right-cut.png"
import gummiGreenSmRightCut from "../images/gummies/gummi-green-sm-right-cut.png"
import gummiRedSmRightCut from "../images/gummies/gummi-red-sm-right-cut.png"
// import signPledgeXl from "../images/gummies/sign-pledge-xl.png"
// import signTodayXl from "../images/gummies/sign-today-xl.png"
import saveGummiAnimals from "../images/gummies/save-gummi-animals.png"
import btnCloseGreen from "../images/btn-close-green.png"
import btnCloseDarkGreen from "../images/btn-close-dark-green.png"
import btnCloseBlue from "../images/btn-close-blue.png"
import signPledgeTop from "../images/gummies/sign-pledge-top.png"
import gummiYellowXl from "../images/gummies/gummi-yellow-xl.png"
import signToday from "../images/gummies/sign-today.png"
import gummiGreenFlip from "../images/gummies/gummi-green-flip.png"
import rainbowMobile from "../images/rainbow-mobile.png"
import signPledgePointer from "../images/sign-pledge-pointer.png"

import rainbow from "../images/rainbow.png"
const App = () => {
  const [gummiBearForest, setGummiBearForest] = useState()
  const [yummiGummi, setYummiGummi] = useState()
  const [fishyRiver, setFishyRiver] = useState()
  const gummiBearForestHandler = () => {
    setFishyRiver(false)
    setYummiGummi(false)
    setGummiBearForest(true)
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer.push({
  //     event: "map",
  //     location: "forest", //forest
  //   })
  }
  const gummiBearForestCloseHandler = () => {
    setGummiBearForest(false)
  }

  const yummiGummiHandler = () => {
    setGummiBearForest(false)
    setFishyRiver(false)
    setYummiGummi(true)
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer.push({
  //     event: "map",
  //     location: "garden", //garden
  //   })
  }
  const yummiGummiCloseHandler = () => {
    setYummiGummi(false)
  }

  const fishyRiverHandler = () => {
    setGummiBearForest(false)
    setYummiGummi(false)
    setFishyRiver(true)
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer.push({
  //     event: "map",
  //     location: "river", //river
  //   })
  }
  const fishyRiverCloseHandler = () => {
    setFishyRiver(false)
  }

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    console.log(data)

    // window.dataLayer = window.dataLayer || []
    // window.dataLayer.push({
    //   event: "emailsubmit",
    //   status: "success",
    //   optin: data.optin,
    //   "marketing optin": data.marketingoptin,
    //   email: data.email,
    // })

    reset()
    //e.target.reset()
  }

  useEffect(() => {
    AOS.init({ duration: 1000, once: true })
    // AOS.refresh()
    //AOS.refreshHard()
    window.addEventListener("load", AOS.refresh)
  })
  let ageCookie = new Cookies()

  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   if (ageCookie.get("isOldEnough") === "yes") {
  //     window.dataLayer.push({
  //       event: "virtualPageview",
  //       page: "/landingpage",
  //     })
  //   }
  // }, [])
  return (
    <>
      <div className="app-container">
        {/* Main Header */}
        <header className="main-header bg-header position-relative map-animation">
          {/* Gummies Left Top */}
          <figure className="mb-0 position-absolute gummi-purple-left-top">
            <img className="img-fluid" src={gummiPurpleXl} alt="Gummi Purple" />
          </figure>
          <div className="container text-center px-0 px-md-3">
            <div className="header-map-hldr map position-relative">
              <figure className="mb-0 position-relative header-map">
                <img className="img-fluid" src={map} alt="Map" />
              </figure>
              <figure className="mb-0 position-absolute logo-skittles-gummies">
                <img
                  className="img-fluid"
                  src={logoSkittlesGummies}
                  alt="Logo Skittles Gummies"
                />
              </figure>
              <figure className="mb-0 position-absolute original-gummies">
                <img
                  className="img-fluid"
                  src={originalGummies}
                  alt="Original Gummies"
                />
              </figure>

              {/* Gummies Left Top */}
              <figure className="mb-0 position-absolute gummi gummi-orange-left-top">
                <img
                  className="img-fluid"
                  src={gummiOrange}
                  alt="Gummi Orange"
                />
              </figure>
              {/* Gummies Left Center */}
              <figure className="mb-0 position-absolute gummi gummi-green-left-center d-none d-md-block">
                <img className="img-fluid" src={gummiGreen} alt="Gummi Green" />
              </figure>
              {/* Gummies Left Bottom */}
              <figure className="mb-0 position-absolute gummi gummi-yellow-left-bottom">
                <img
                  className="img-fluid"
                  src={gummiYellow}
                  alt="Gummi Yellow"
                />
              </figure>

              {/* Gummies Right Top */}
              <figure className="mb-0 position-absolute gummi gummi-purple-right-top">
                <img
                  className="img-fluid"
                  src={gummiPurple}
                  alt="Gummi Purple"
                />
              </figure>
              <figure className="mb-0 position-absolute gummi gummi-red-right-top">
                <img className="img-fluid" src={gummiRed} alt="Gummi Red" />
              </figure>
              <figure className="mb-0 position-absolute gummi gummi-yellow-right-top">
                <img
                  className="img-fluid"
                  src={gummiYellow}
                  alt="Gummi Yellow"
                />
              </figure>

              {/* Gummies Right Bottom */}
              <figure className="mb-0 position-absolute gummi gummi-green-right-bottom">
                <img className="img-fluid" src={gummiGreen} alt="Gummi Green" />
              </figure>
              <figure className="mb-0 position-absolute gummi gummi-orange-right-bottom">
                <img
                  className="img-fluid"
                  src={gummiOrange}
                  alt="Gummi Orange"
                />
              </figure>

              {/* Gummies Bottom Left */}
              <figure className="mb-0 position-absolute gummi gummi-red-bottom-left">
                <img className="img-fluid" src={gummiRed} alt="Gummi Red" />
              </figure>
              {/* Gummies Bottom Left */}
              <figure className="mb-0 position-absolute gummi gummi-purple-bottom-right">
                <img
                  className="img-fluid"
                  src={gummiPurple}
                  alt="Gummi Purple"
                />
              </figure>
              <figure className="mb-0 position-absolute gummi gummi-yellow-bottom-right">
                <img
                  className="img-fluid"
                  src={gummiYellow}
                  alt="Gummi Yellow"
                />
              </figure>
            </div>
          </div>
        </header>

        {/* Welcome Section */}
        <section className="welcome bg-welcome-cloud position-relative">
          <div className="container text-center">
            <div className="content position-relative">
              <h1 className="text-uppercase text-blue" data-aos="fade-up">
                Welcome to{" "}
                <span className="d-block">
                  the Skittles<sup>&reg;</sup>
                </span>{" "}
                Gummi Sanctuary
              </h1>
              <p className="large text-blue" data-aos="fade-up">
                We celebrate the oh-so squishy, delightfully{" "}
                <span className="d-md-block">
                  vibrant candies of SKITTLES Gummies&#8212;
                </span>{" "}
                no animal mascot necessary.
              </p>

              <p className="large text-blue px-4 px-md-0" data-aos="fade-up">
                If you truly love gummi animals, help us{" "}
                <span className="d-md-block">
                  set them free by choosing SKITTLES Gummies.
                </span>{" "}
                <span className="d-md-block">
                  Now, when the desire for gummies hits,
                </span>{" "}
                <span className="d-md-block">
                  there's a new (***and in our opinion, better)
                </span>{" "}
                alternative to the same squishy treat.
              </p>

              <figure className="mb-0 position-absolute skt-gummies-originals">
                <img
                  className="img-fluid"
                  src={sktGummiesOriginals}
                  alt="Skittles Gummies Originals"
                  data-aos="zoom-out"
                />
              </figure>
              <figure className="mb-0 position-absolute skt-gummies-wild-berry">
                <img
                  className="img-fluid"
                  src={sktGummiesWildBerry}
                  alt="Skittles Gummies Wild Berry"
                  data-aos="zoom-out"
                />
              </figure>
            </div>
          </div>

          <figure className="mb-0 position-absolute gummi-green-left-bottom">
            <img
              className="img-fluid"
              src={gummiGreenLg}
              alt="Gummi Green"
              data-aos="zoom-in"
            />
          </figure>
        </section>

        {/* PSA Section */}
        <section className="psa position-relative bg-red">
          <div className="container text-center">
            <div className="content px-2 px-md-0">
              <h2
                className="text-uppercase text-white px-5 px-md-0 mx-2 mx-md-0"
                data-aos="fade-up"
              >
                It is time to put an{" "}
                <span className="d-md-block">end to Gummi Animal</span>{" "}
                <span className="d-md-block">consumption Forever</span>
              </h2>

              <p className="large text-white px-5 px-md-0" data-aos="fade-up">
                Help us create a safe haven,{" "}
                <span className="d-md-block">
                  with the SKITTLES Gummi Sanctuary.
                </span>
              </p>

              <h3
                className="text-blue px-3 px-md-0 d-md-none"
                data-aos="fade-up"
              >
                <span className="d-block">Watch our PSA to </span>
                <span className="d-block">find out how you can </span>
                <span className="d-block text-yellow">ACT NOW BEFORE</span>
                <span className="d-block text-yellow">IT'S TOO LATE!!!</span>
              </h3>

              <h3
                className="text-blue px-3 px-md-0 d-none d-md-block"
                data-aos="fade-up"
              >
                <span className="d-block">Watch our PSA to find out how</span>
                <span className="d-md-block">
                  you can <span className="text-yellow">ACT NOW BEFORE</span>
                </span>
                <span className="d-md-block text-yellow">IT'S TOO LATE!!!</span>
              </h3>

              <p className="large text-white" data-aos="fade-up">
                By choosing SKITTLES Gummies, you{" "}
                <span className="d-md-block">
                  can help gummi animals around the world live
                </span>{" "}
                <span className="d-md-block">
                  their lives in peace for National Gummi Day.
                </span>
              </p>

              <div className="video-hldr position-relative">
                <figure className="mb-0 position-absolute gummi-purple-top-center">
                  <img
                    className="img-fluid"
                    src={gummiPurpleSm}
                    alt="Gummi Purple"
                    data-aos="zoom-in"
                  />
                </figure>
                <figure className="mb-0 position-absolute gummi-orange-left-top">
                  <img
                    className="img-fluid"
                    src={gummiOrangeSm}
                    alt="Gummi Orange"
                    data-aos="zoom-in"
                  />
                </figure>
                <figure className="mb-0 position-absolute gummi-yellow-right-top">
                  <img
                    className="img-fluid"
                    src={gummiYellowSm}
                    alt="Gummi Yellow"
                    data-aos="zoom-in"
                  />
                </figure>

                <div
                  className="embed-responsive embed-responsive-16by9"
                  data-aos="fade-up"
                >
                  <ReactPlayer
                    url="https://www.youtube.com/watch?v=5wYvw7I2LGQ"
                    width="100%"
                    height="100%"
                    controls={true}
                  />
                  {/* <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                    allowFullScreen
                  ></iframe> */}
                </div>
              </div>
            </div>
          </div>

          <figure className="mb-0 position-absolute gummi-yellow-left-top d-none d-md-block">
            <img
              className="img-fluid"
              src={gummiYellowSm}
              alt="Gummi Yellow"
              data-aos="zoom-in"
            />
          </figure>
          <figure className="mb-0 position-absolute gummi-red-left-center">
            <img
              className="img-fluid"
              src={gummiRedSm}
              alt="Gummi Red"
              data-aos="zoom-in"
            />
          </figure>
          <figure className="mb-0 position-absolute gummi-green-left-center d-none d-md-block">
            <img
              className="img-fluid"
              src={gummiGreenSm}
              alt="Gummi Green"
              data-aos="zoom-in"
            />
          </figure>

          <figure className="mb-0 position-absolute gummi-orange-right-top">
            <img
              className="img-fluid"
              src={gummiOrangeRightCut}
              alt="Gummi Orange"
              data-aos="fade-left"
            />
          </figure>

          <figure className="mb-0 position-absolute gummi-yellow-right-center">
            <img
              className="img-fluid"
              src={gummiYellowSm}
              alt="Gummi Yellow"
              data-aos="zoom-in"
            />
          </figure>
          <figure className="mb-0 position-absolute gummi-green-right-center">
            <img
              className="img-fluid"
              src={gummiGreenSmRightCut}
              alt="Gummi Green"
              data-aos="fade-left"
            />
          </figure>
          <figure className="mb-0 position-absolute gummi-red-right-bottom d-none d-md-block">
            <img
              className="img-fluid"
              src={gummiRedSmRightCut}
              alt="Gummi Red"
              data-aos="zoom-in"
            />
          </figure>
        </section>

        {/* Pledge Section */}
        <section className="pledge bg-pledge-cloud position-relative">
          {/* Gummies Left Top */}
          <figure className="mb-0 position-absolute gummi-purple-left-top">
            <img
              className="img-fluid"
              src={gummiPurpleXl}
              alt="Gummi Purple"
              data-aos="zoom-in"
            />
          </figure>

          {/* Gummies right Top */}
          <div className="sign-pledge-top position-absolute" data-aos>
            <figure className="mb-0 position-relative">
              <img
                className="img-fluid sign-pledge position-absolute"
                src={signPledgeTop}
                alt="Sign the Pledge"
              />
              <img
                className="img-fluid gummi-yellow position-relative"
                src={gummiYellowXl}
                alt="Gummi Yellow"
                data-aos="zoom-in"
              />
            </figure>
          </div>

          <div className="sign-today-bottom position-absolute" data-aos>
            <figure className="mb-0 position-relative">
              <img
                className="img-fluid sign-today position-absolute"
                src={signToday}
                alt="Sign Today"
              />
              <img
                className="img-fluid gummi-green-flip position-relative d-md-none"
                src={gummiGreenFlip}
                alt="Gummi Green"
                data-aos="zoom-in"
              />
              <img
                className="img-fluid gummi-green-flip position-relative d-none d-md-block"
                src={gummiGreenXl}
                alt="Gummi Green"
                data-aos="zoom-in"
              ></img>
            </figure>
          </div>

          <figure
            className="mb-0 position-absolute save-gummi-animals d-none d-md-block"
            data-aos="fade-up"
          >
            <img
              className="img-fluid"
              src={saveGummiAnimals}
              alt="Save Gummi Animals"
            />
          </figure>

          <div className="container text-center">
            <div className="content">
              <h2
                className="text-uppercase text-blue d-md-none"
                data-aos="fade-up"
              >
                <span className="d-block">Squish the </span>
                <span className="d-block">Rainbow, not </span>
                <span className="d-block">the Gummi Animals </span>
              </h2>

              <h2
                className="text-uppercase text-blue d-none d-md-block"
                data-aos="fade-up"
              >
                Squish the Rainbow,{" "}
                <span className="d-md-block">not the Gummi Animals</span>
              </h2>

              <h3
                className="text-uppercase text-red d-md-none"
                data-aos="fade-up"
              >
                <span className="d-block">Don't wait, </span>
                <span className="d-block">sign today! </span>
              </h3>

              <h3
                className="text-uppercase text-red d-none d-md-block"
                data-aos="fade-up"
              >
                Don't wait, sign today!
              </h3>

              <p className="large text-blue px-3 px-md-0" data-aos="fade-up">
                By signing our Squish the Rainbow pledge, you're taking the
                first step in stopping gummi animal consumption and choosing an
                option your tastebuds will thank you for. Your pledge will allow
                these gummies to freely live their best squishy lives in the
                SKITTLES Gummi Sanctuary.
              </p>

              <p className="large text-blue px-3 px-md-0" data-aos="fade-up">
                As a big, huge THANK YOU for joining our movement, you'll also
                be entered for a chance to win the biggest, squishiest prize:
                <span className="d-block nunito-black">
                  Enough SKITTLES Gummies to
                </span>{" "}
                <span className="d-block nunito-black">
                  make your tastebuds rejoice!
                </span>
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="email-field-parent position-relative">
              <figure className="mb-0 position-absolute sign-pledge-pointer">
                <img
                  className="img-fluid"
                  src={signPledgePointer}
                  alt="Sign Pledge"
                  data-aos="fade-left"
                />
              </figure>
              <div className="email-field" data-aos="fade-up">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Email Address"
                    {...register("email", {
                      required: "Email address is required to sign the pledge",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Please enter a valid email address",
                      },
                    })}
                  />

                  <button className="btn" type="submit">
                    Sign
                  </button>
                </div>
                <div className="mt-2 px-3 px-md-5 text-center">
                  {errors.email && (
                    <span className="input-error" style={{ color: "red" }}>
                      {errors.email.message}
                    </span>
                  )}
                </div>
                <div className="form-group form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register("optin", {
                        required:
                          "Please acknowledge you’ve read the terms and conditions, privacy policy and are over the age of 16.",
                      })}
                    />{" "}
                    By clicking "Sign the Pledge", I agree to the{" "}
                    <a
                      href="/skittles-gummies-terms-and-conditions.pdf"
                      target="_blank"
                      className="text-green"
                    >
                      Terms and Conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.mars.com/privacy"
                      target="_blank"
                      className="text-red"
                      rel="nofollow noreferrer"
                    >
                      Privacy Policy
                    </a>{" "}
                    and confirm that I am over the age of 16
                  </label>
                </div>
                <div className="pl-3 pl-md-5 ">
                  {errors.optin && (
                    <span className="input-error" style={{ color: "red" }}>
                      {errors.optin.message}
                    </span>
                  )}
                </div>
                <div className="form-group form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register("marketingoptin")}
                    />
                    I would like to receive future marketing communications from
                    SKITTLES® and other Mars Wrigley’s brands via the email
                    provided. You can withdraw this consent at any time.
                  </label>
                </div>
              </div>
            </div>
          </form>
          <div className="hide-helper position-absolute d-none d-md-block"></div>
        </section>

        {/* Map Section */}
        <section className="map-section position-relative bg-red">
          <div className="container text-center px-0 px-md-3">
            {/* Grand Opening Content */}
            {/* <div className="content">
              <p className="large nunito-black text-white" data-aos="fade-up">
                <span className="d-block">Welcome to</span>
                <span className="d-block">The Grand Opening</span>
                <span className="d-block">of the</span>
              </p>
              <h2
                className="text-uppercase text-white large"
                data-aos="fade-up"
              >
                Skittles <span className="d-md-block">Gummi Sanctuary</span>
              </h2>
              <p
                className="large text-white px-3 px-md-0 mx-3 mx-md-auto"
                data-aos="fade-up"
              >
                As a loyal Skittles Gummi Sanctuary supporter, come and see just
                how many gummi animals we’ve rescued! We couldn’t be more
                excited to let you explore all our Gummi Sanctuary has to offer
                these cute, colorful and most importantly, safe, gummi animals.
              </p>
            </div> */}

            {/* <div className="video-hldr position-relative px-3 px-md-0">
              <div
                className="embed-responsive embed-responsive-16by9"
                data-aos="fade-up"
              >
                <ReactPlayer
                  url="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            </div>

            <h3
              className="text-yellow nunito-black d-md-none"
              data-aos="fade-up"
            >
              <span className="d-block">Explore our </span>
              <span className="d-block">safe-haven</span>
              <span className="d-block">habitats!</span>
            </h3>

            <h3
              className="text-yellow nunito-black d-none d-md-block mb-md-5"
              data-aos="fade-up"
            >
              <span className="d-block">Explore our </span>
              <span className="d-block">safe-haven habitats!</span>
            </h3> */}

            {/* Sneak Peak Content */}
            <div className="content">
              <h3 className="large nunito-black text-white" data-aos="fade-up">
                <span className="d-block">COMING SOON:</span>
                <span className="d-block">The Grand Opening</span>
                <span className="d-block">of the</span>
              </h3>
              <h2
                className="text-uppercase text-white large px-4 px-md-0"
                data-aos="fade-up"
              >
                Skittles <span className="d-md-block">Gummi Sanctuary</span>
              </h2>
              <h3
                className="text-yellow nunito-black px-5 px-md-0"
                data-aos="fade-up"
              >
                <span className="d-md-block">Enjoy a sneak preview of </span>
                <span className="d-md-block">our safe-haven habitats!</span>
              </h3>
              <p
                className="large text-white px-3 px-md-0 mx-3 mx-md-auto"
                data-aos="fade-up"
              >
                Make sure to show your support by spreading awareness and
                signing the pledge. Please check back on July 15th to see how
                your pledge made an impact!
              </p>
            </div>

            <div className="map position-relative" data-aos>
              <figure className="mb-0 position-relative header-map">
                <img className="img-fluid" src={map} alt="Map" />
              </figure>

              <div className="pointer-hldr position-absolute green">
                <figure className="mb-0 position-relative">
                  <img
                    className="img-fluid pointer position-relative"
                    src={pointerGreen}
                    alt="Pointer Green"
                    onClick={yummiGummiHandler}
                  />
                  <img
                    className="img-fluid pointer-shadow position-absolute"
                    src={pointerShadow}
                    alt="Pointer Shadow"
                  />
                </figure>
              </div>

              <div className="pointer-hldr position-absolute blue">
                <figure className="mb-0 position-relative">
                  <img
                    className="img-fluid pointer position-relative"
                    src={pointerBlue}
                    alt="Pointer Blue"
                    onClick={fishyRiverHandler}
                  />
                  <img
                    className="img-fluid pointer-shadow position-absolute"
                    src={pointerShadow}
                    alt="Pointer Shadow"
                  />
                </figure>
              </div>

              <div className="pointer-hldr position-absolute dark-green">
                <figure className="mb-0 position-relative">
                  <img
                    className="img-fluid pointer position-relative"
                    src={pointerDarkGreen}
                    alt="Pointer Dark Green"
                    onClick={gummiBearForestHandler}
                  />
                  <img
                    className="img-fluid pointer-shadow position-absolute"
                    src={pointerShadow}
                    alt="Pointer Shadow"
                  />
                </figure>
              </div>

              <div
                id="gummi-bear-forest"
                className={`map-modal gummi-bear-forest position-absolute ${gummiBearForest &&
                  "in"}`}
              >
                <img
                  id="gummi-bear-forest-close"
                  className="position-absolute btn-close"
                  src={btnCloseDarkGreen}
                  alt="Close Button"
                  onClick={gummiBearForestCloseHandler}
                />
                <h3 className="nunito-black">Gummi Bear Forest</h3>
                <p className="large mb-0">
                  We welcome gummi bears of all flavors to hibernate here
                  without fear of waking up in plastic packaging in the candy
                  aisle.
                </p>
              </div>

              <div
                id="yummi-gummi"
                className={`map-modal yummi-gummi position-absolute ${yummiGummi &&
                  "in"}`}
              >
                <img
                  id="yummi-gummi-close"
                  className="position-absolute btn-close"
                  src={btnCloseGreen}
                  alt="Close Button"
                  onClick={yummiGummiCloseHandler}
                />
                <h3 className="nunito-black">Yummy Gummi Worm Garden</h3>
                <p className="large mb-0">
                  Here, these two-toned gummi critters are free to do what they
                  do best - pretty much nothing - but we believe they too
                  deserve a place to do nothing in peace.
                </p>
              </div>

              <div
                id="fishy-river"
                className={`map-modal fishy-river position-absolute ${fishyRiver &&
                  "in"}`}
              >
                <img
                  id="fishy-river-close"
                  className="position-absolute btn-close"
                  src={btnCloseBlue}
                  alt="Close Button"
                  onClick={fishyRiverCloseHandler}
                />
                <h3 className="nunito-black">Squishy Fishy River</h3>
                <p className="large mb-0">
                  Native to the lakes, rivers and seas of the North, the gummi
                  fish and sharks can float here in harmony knowing their future
                  is free of swimming downstream through tummy tunnels.
                </p>
              </div>

              {/* Gummies Left Top */}
              <figure className="mb-0 position-absolute gummi gummi-orange-left-top">
                <img
                  className="img-fluid"
                  src={gummiOrange}
                  alt="Gummi Orange"
                />
              </figure>
              {/* Gummies Left Center */}
              <figure className="mb-0 position-absolute gummi gummi-green-left-center">
                <img className="img-fluid" src={gummiGreen} alt="Gummi Green" />
              </figure>
              {/* Gummies Left Bottom */}
              <figure className="mb-0 position-absolute gummi gummi-yellow-left-bottom">
                <img
                  className="img-fluid"
                  src={gummiYellow}
                  alt="Gummi Yellow"
                />
              </figure>

              {/* Gummies Right Top */}
              <figure className="mb-0 position-absolute gummi gummi-purple-right-top">
                <img
                  className="img-fluid"
                  src={gummiPurple}
                  alt="Gummi Purple"
                />
              </figure>
              <figure className="mb-0 position-absolute gummi gummi-red-right-top">
                <img className="img-fluid" src={gummiRed} alt="Gummi Red" />
              </figure>
              <figure className="mb-0 position-absolute gummi gummi-yellow-right-top">
                <img
                  className="img-fluid"
                  src={gummiYellow}
                  alt="Gummi Yellow"
                />
              </figure>

              {/* Gummies Right Bottom */}
              <figure className="mb-0 position-absolute gummi gummi-green-right-bottom">
                <img className="img-fluid" src={gummiGreen} alt="Gummi Green" />
              </figure>
              <figure className="mb-0 position-absolute gummi gummi-orange-right-bottom">
                <img
                  className="img-fluid"
                  src={gummiOrange}
                  alt="Gummi Orange"
                />
              </figure>

              {/* Gummies Bottom Left */}
              <figure className="mb-0 position-absolute gummi gummi-red-bottom-left">
                <img className="img-fluid" src={gummiRed} alt="Gummi Red" />
              </figure>
              {/* Gummies Bottom Left */}
              <figure className="mb-0 position-absolute gummi gummi-purple-bottom-right">
                <img
                  className="img-fluid"
                  src={gummiPurple}
                  alt="Gummi Purple"
                />
              </figure>
              <figure className="mb-0 position-absolute gummi gummi-yellow-bottom-right">
                <img
                  className="img-fluid"
                  src={gummiYellow}
                  alt="Gummi Yellow"
                />
              </figure>
            </div>
          </div>
        </section>

        {/* Rainbow Section */}
        <section className="rainbow-section bg-rainbow-cloud position-relative">
          <figure className="mb-0 position-relative rainbow-img d-md-none">
            <img className="img-fluid" src={rainbowMobile} alt="Rainbow" />
          </figure>
          <figure className="mb-0 position-absolute rainbow-img d-none d-md-block">
            <img className="img-fluid" src={rainbow} alt="Rainbow" />
          </figure>
          <div className="container text-center">
            <div className="row">
              <div className="col-md-8 col-lg-7">
                <div className="rainbow-content">
                  <h3
                    className="large nunito-black text-red"
                    data-aos="fade-up"
                  >
                    The All-New
                  </h3>
                  <h2
                    className="text-uppercase text-white large"
                    data-aos="fade-up"
                  >
                    Skittles <span className="d-md-block">Gummies!</span>
                  </h2>
                  <p className="large text-blue" data-aos="fade-up">
                    <span className="text-yellow nunito-black rubber-band d-inline-block">
                      Bite-sized,&nbsp;
                    </span>{" "}
                    soft gummy candies from the candy brand you know and love.
                    Available in the{" "}
                    <span className="text-red nunito-black">
                      <span className="d-inline-block rubber-band">
                        Original&nbsp;{" "}
                      </span>
                      <span className="d-inline-block rubber-band">
                        Five&nbsp;
                      </span>
                      <span className="d-inline-block rubber-band">
                        Fruity&nbsp;{" "}
                      </span>
                      <span className="d-inline-block rubber-band">
                        Flavors,&nbsp;{" "}
                      </span>
                    </span>{" "}
                    and{" "}
                    <span className="text-blue nunito-black">
                      <span className="d-inline-block rubber-band">
                        Wild&nbsp;
                      </span>
                      <span className="d-inline-block rubber-band">
                        Berry&nbsp;
                      </span>
                      <span className="d-inline-block rubber-band">
                        varieties,{" "}
                      </span>
                    </span>{" "}
                    SKITTLES Gummies deliver a{" "}
                    <span className="text-green nunito-black">
                      <span className="d-inline-block rubber-band">
                        exciting&nbsp;{"  "}
                      </span>
                      <span className="d-inline-block rubber-band">
                        and&nbsp;
                      </span>
                      <span className="d-inline-block rubber-band">
                        unpredictable&nbsp;{" "}
                      </span>
                      <span className="d-inline-block rubber-band">
                        flavor&nbsp;{" "}
                      </span>
                      <span className="d-inline-block rubber-band">
                        experience&nbsp;
                      </span>
                    </span>{" "}
                    that can be experienced one intense flavor at a time or all
                    together{" "}
                    <span className="text-orange nunito-black">
                      <span className="d-inline-block rubber-band">
                        exciting&nbsp;{" "}
                      </span>
                      <span className="d-inline-block rubber-band">
                        combos&nbsp;{" "}
                      </span>
                    </span>{" "}
                    and{" "}
                    <span className="text-purple nunito-black">
                      <span className="d-inline-block rubber-band">
                        unpredictable&nbsp;{" "}
                      </span>
                      <span className="d-inline-block rubber-band">
                        flavor-&nbsp;
                      </span>
                      <span className="d-inline-block rubber-band">
                        bomb&nbsp;{" "}
                      </span>
                      <span className="d-inline-block rubber-band">
                        experience.&nbsp;
                      </span>
                    </span>{" "}
                    Simply said, SKITTLES Gummies are the best gummies in
                    existence*... (*in our opinion)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default App
